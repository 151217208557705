import React, { useContext } from "react";
import Layout from "../../../components/Layout/layout";
import WeekendTripPage from "../../../components/WeekendTrip-blog/german-blog";
import SEO from "../../../components/SEO/seo";
const WeekendTrip = () => {
	return (
		<Layout>
			<SEO
				title="Taxiservice eine Wochenendreise wert - Perfekt in Hamburg"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="Der Taxiservice erweist sich als kostengünstige Möglichkeit, die Orte am Wochenende zu besuchen. Sie können das Taxi für eine bequeme Fahrt buchen, um die nächstgelegenen Orte zu besuchen."
			/>
			<WeekendTripPage />
		</Layout>
	);
};

export default WeekendTrip;
